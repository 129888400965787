import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Img from "gatsby-image"
//add loadable here:
import FormEbook from '../../components/FormEbook'
import Helmet from 'react-helmet'

export default class Index extends React.Component {
  componentDidMount(){
    document.getElementById('lang-switch').href = 'https://e-multicontent.pl/ebook/'
  }

  render() {
    const  { data } = this.props
    return (
      <>
      <Helmet titleTemplate="Ebook - How to increase your e-shop sales in five easy steps | e-multicontent">
        <title>Ebook - How to easily increase sales in an e-shop? | e-multicontent</title>
        <meta name="description" content={'Subscribe to receive our free e-book. How to increase your e-shop sales in five easy steps. ✔️'}/>
        <meta name='image' content={'https://e-multicontent.com/img/apple-touch-icon.png'} />
        <meta name='keywords' content={'e-book, copywriting, copywriters, marketing, copywriting agency, team of copywriters, work, business, content, content, translations, SEO, blog, texts, articles, graphics, video, websites, online stores, marketplace, e-commerce'} />
        <meta property="og:description" content={'Subscribe to receive our free e-book. How to increase your e-shop sales in five easy steps. ✔️'}/>
        <meta property='og:title' content={'Ebook - How to easily increase sales in an e-shop?  | e-multicontent'} />
        <meta property='og:image' content={'https://e-multicontent.com/img/apple-touch-icon.png'} />
        <meta property="og:url" content={'https://e-multicontent.com/ebook/'}/>
        <link rel="alternate" href="https://e-multicontent.pl/ebook/" hrefLang="pl-pl" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="e-multicontent.com" />
        <meta property="twitter:url" content="https://e-multicontent.com/ebook/" />
        <meta name="twitter:title" content="Ebook - How to easily increase sales in an e-shop? | e-multicontent" />
        <meta name="twitter:description" content="Subscribe to receive our free e-book. How to increase your e-shop sales in five easy steps. ✔️" />
        <meta name="twitter:image" content="https://e-multicontent.com/img/apple-touch-icon.png" />

        <script type='application/ld+json'>
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "https://e-multicontent.com"
          },{
            "@type": "ListItem",
            "position": 2,
            "name": "Ebook",
            "item": "https://e-multicontent.com/ebook/"
          }]
        })}
        </script>

      </Helmet>
      <Layout style={{backgroundColor:'rgba(255,255,255,0.0)'}}>
      <nav className="breadcrumbs-nav section">
          <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{fontWeight:'600',color:'white'}}><b>Ebook</b></span>
      </nav>
      <Img fluid={data.file.childImageSharp.fluid}
      className="career-image"
      loading={'eager'}
      alt="E-book e-multicontent - copywriting, translations, seo, websites, e-commerce, marketplace"
      style={{
        marginTop:'0px',
        width: '100%',
        position: 'absolute',
        minHeight: '900px',
        zIndex: '-1',
      }} />
        <section style={{backgroundColor:'rgba(0,0,0,.4)'}}>

        <div  style={{borderRadius:'10px',padding:'5px',width:'100%'}}>
        {/*message*/}
        <div className="container" id="ebook"  style={{backgroundColor:'rgba(0,0,0,0)',padding:'5%',margin:'auto'}}>
        <h1 className="title" style={{color:'white',fontSize:'2em',marginTop:'25px',marginLeft:'40px'}}>E-book</h1>
        {/*<iframe id="fm-fc-f-ozzh90n74h" src="https://forms.freshmail.io/f/70jqexgyfc/ozzh90n74h/index.html" title="ebook" frameBorder="0" marginHeight="0" marginWidth="0" width="100%" style={{minHeight: "600px"}}></iframe>*/}
        <FormEbook />
        </div>
        </div>

        </section>


        <div id="ebookbot" style={{paddingTop:'calc(5%)',position:'relative',paddingBottom:'50px',backgroundColor:'white'}} align="center">
        <br></br><br></br>
        <p style={{color:'#222222'}}><b>Would you rather contact us via email?</b></p>
        <br></br>
        <p><a href="mailto:info@e-multicontent.com" rel="noopener noreferrer" className="btn" target="_blank">info@e-multicontent.com</a></p>
        <br></br>

        <p style={{color:'#222222'}}><b>Would you prefer to give us a call?</b></p>
        <br></br>
        <p><a href="tel:+48717591140" rel="noopener noreferrer" className="btn" target="_blank">+48717591140</a></p>
        <br></br>




        <br></br>


        <h3 style={{color:'#29b1ff'}}>Pay us a visit at your convenience::</h3>
        <br></br>
        <h4 style={{color:'#222222'}}>Katarzyńska street 1F,<br></br> 55-010 <b>Radwanice</b></h4>
        <sub>The office is open from<b> 8 am to 4 pm</b>,<b> Monday to Friday.</b></sub>
        <br></br>
        <br></br>
        <br></br>
        <div>
        <Link align="center" className="btn" style={{zIndex:'10000'}} to="/"> Return to the website</Link>
        </div>
        </div>


      </Layout>
      </>
    )
  }

}

export const query = graphql`
  query FilesEbook{
    file(relativePath: {regex: "/optimized/ebooktlo.jpg/"}) {
      childImageSharp {
        fluid(fit: COVER) {
        srcWebp
        src
        srcSet
        srcSetWebp
      }
      }
    }
  }
`
