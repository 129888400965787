import React from 'react'
import { navigate } from 'gatsby-link'
import { Link } from 'gatsby'
import Mailgun from 'mailgun.js'

const API_KEY = process.env.REACT_APP_MG_API_KEY
const DOMAIN = process.env.REACT_APP_MG_DOMAIN

const formData = require('form-data');
const mailgun = new Mailgun(formData);
const client = mailgun.client({username: 'api', key: API_KEY});

const htmldata = `<!DOCTYPE html>
<html
  xmlns:v="urn:schemas-microsoft-com:vml"
  xmlns:o="urn:schemas-microsoft-com:office:office"
  lang="en"
>
  <head>
    <title></title>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta charset="UTF-8" />
    <!--[if mso
      ]><xml
        ><o:OfficeDocumentSettings
          ><o:PixelsPerInch>96</o:PixelsPerInch
          ><o:AllowPNG /></o:OfficeDocumentSettings></xml
    ><![endif]-->
    <!--[if !mso]><!-->
    <link
      href="https://fonts.googleapis.com/css?family=Lato"
      rel="stylesheet"
      type="text/css"
    />
    <!--<![endif]-->
    <style>
      * {
        box-sizing: border-box;
      }

      body {
        margin: 0;
        padding: 0;
      }

      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: inherit !important;
      }

      #MessageViewBody a {
        color: inherit;
        text-decoration: none;
      }

      p {
        line-height: inherit;
      }

      .desktop_hide,
      .desktop_hide table {
        mso-hide: all;
        display: none;
        max-height: 0px;
        overflow: hidden;
      }

      .image_block img + div {
        display: none;
      }

      .menu_block.desktop_hide .menu-links span {
        mso-hide: all;
      }

      @media (max-width: 700px) {
        .desktop_hide table.icons-inner,
        .social_block.desktop_hide .social-table {
          display: inline-block !important;
        }

        .icons-inner {
          text-align: center;
        }

        .icons-inner td {
          margin: 0 auto;
        }

        .image_block img.fullWidth {
          max-width: 100% !important;
        }

        .mobile_hide {
          display: none;
        }

        .row-content {
          width: 100% !important;
        }

        .stack .column {
          width: 100%;
          display: block;
        }

        .mobile_hide {
          min-height: 0;
          max-height: 0;
          max-width: 0;
          overflow: hidden;
          font-size: 0px;
        }

        .desktop_hide,
        .desktop_hide table {
          display: table !important;
          max-height: none !important;
        }
      }
    </style>
  </head>

  <body
    style="
      background-color: #e6f0ef;
      margin: 0;
      padding: 0;
      -webkit-text-size-adjust: none;
      text-size-adjust: none;
    "
  >
    <table
      class="nl-container"
      width="100%"
      border="0"
      cellpadding="0"
      cellspacing="0"
      role="presentation"
      style="
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
        background-color: #e6f0ef;
      "
    >
      <tbody>
        <tr>
          <td>
            <table
              class="row row-1"
              align="center"
              width="100%"
              border="0"
              cellpadding="0"
              cellspacing="0"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      class="row-content"
                      align="center"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      role="presentation"
                      style="
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        background-position: center top;
                        color: #000;
                        width: 680px;
                        margin: 0 auto;
                        background-color: #ffffff;
                      "
                      width="680"
                    >
                      <tbody>
                        <tr>
                          <td
                            class="column column-1"
                            width="100%"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              font-weight: 400;
                              text-align: left;
                              vertical-align: top;
                              border-top: 0px;
                              border-right: 0px;
                              border-bottom: 0px;
                              border-left: 0px;
                            "
                          >
                            <div
                              class="spacer_block block-1"
                              style="
                                height: 15px;
                                line-height: 15px;
                                font-size: 1px;
                              "
                            >
                              &#8202;
                            </div>
                            <table
                              class="image_block block-2"
                              width="100%"
                              border="0"
                              cellpadding="15"
                              cellspacing="0"
                              role="presentation"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                              "
                            >
                              <tr>
                                <td class="pad">
                                  <div
                                    class="alignment"
                                    align="center"
                                    style="line-height: 10px"
                                  >
                                    <img
                                      src="https://e-multicontent.com/img/logo-e-black.jpg"
                                      style="
                                        display: block;
                                        height: auto;
                                        border: 0;
                                        max-width: 150px;
                                        width: 100%;
                                      "
                                      width="136"
                                      alt="logo"
                                      title="logo"
                                    />
                                  </div>
                                </td>
                              </tr>
                            </table>
                            <table
                              class="menu_block block-4"
                              width="100%"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              role="presentation"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                              "
                            >
                              <tr>
                                <td
                                  class="pad"
                                  style="
                                    color: #021f2a;
                                    font-family: Georgia, Times,
                                      'Times New Roman', serif;
                                    font-size: 14px;
                                    letter-spacing: 1px;
                                    text-align: center;
                                  "
                                >
                                  <table
                                    width="100%"
                                    cellpadding="0"
                                    cellspacing="0"
                                    border="0"
                                    role="presentation"
                                    style="
                                      mso-table-lspace: 0pt;
                                      mso-table-rspace: 0pt;
                                    "
                                  >
                                    <tr>
                                      <td
                                        class="alignment"
                                        style="
                                          text-align: center;
                                          font-size: 0px;
                                        "
                                      >
                                        <div class="menu-links">
                                          <!--[if mso]><table role="presentation" border="0" cellpadding="0" cellspacing="0" align="center" style=""><tr style="text-align:center;"><![endif]-->

                                          <!--[if mso]><td style="padding-top:15px;padding-right:10px;padding-bottom:5px;padding-left:10px"><![endif]-->
                                          <a
                                            href="https://e-multicontent.com"
                                            target="_blank"
                                            style="
                                              mso-hide: false;
                                              padding-top: 15px;
                                              padding-bottom: 5px;
                                              padding-left: 10px;
                                              padding-right: 10px;
                                              display: inline-block;
                                              color: #021f2a;
                                              font-family: Lato, Poppins,
                                                'Helvetica', non-serif;
                                              font-size: 14px;
                                              text-decoration: none;
                                              letter-spacing: 1px;
                                            "
                                            >Home</a
                                          ><!--[if mso]></td><td><!
                                          [endif]--><span
                                            class="sep"
                                            style="
                                              font-size: 14px;
                                              font-family: Georgia, Times,
                                                'Times New Roman', serif;
                                              color: #021f2a;
                                            "
                                            >|</span
                                          ><!--[if mso]></td><!
                                          [endif]--><!--[if mso]><td style="padding-top:15px;padding-right:10px;padding-bottom:5px;padding-left:10px"><!
                                          [endif]--><a
                                            href="https://e-multicontent.com/blog/"
                                            target="_self"
                                            style="
                                              mso-hide: false;
                                              padding-top: 15px;
                                              padding-bottom: 5px;
                                              padding-left: 10px;
                                              padding-right: 10px;
                                              display: inline-block;
                                              color: #021f2a;
                                              font-family: Lato, Poppins,
                                                'Helvetica', non-serif;
                                              font-size: 14px;
                                              text-decoration: none;
                                              letter-spacing: 1px;
                                            "
                                            >Blog</a
                                          ><!--[if mso]></td><td><!
                                          [endif]--><span
                                            class="sep"
                                            style="
                                              font-size: 14px;
                                              font-family: Georgia, Times,
                                                'Times New Roman', serif;
                                              color: #021f2a;
                                            "
                                            >|</span
                                          ><!--[if mso]></td><!
                                          [endif]--><!--[if mso]><td style="padding-top:15px;padding-right:10px;padding-bottom:5px;padding-left:10px"><!
                                          [endif]--><a
                                            href="https://e-multicontent.com/offer/"
                                            target="_blank"
                                            style="
                                              mso-hide: false;
                                              padding-top: 15px;
                                              padding-bottom: 5px;
                                              padding-left: 10px;
                                              padding-right: 10px;
                                              display: inline-block;
                                              color: #021f2a;
                                              font-family: Lato, Poppins,
                                                'Helvetica', non-serif;
                                              font-size: 14px;
                                              text-decoration: none;
                                              letter-spacing: 1px;
                                            "
                                            >Offer</a
                                          ><!--[if mso]></td><td><!
                                          [endif]--><span
                                            class="sep"
                                            style="
                                              font-size: 14px;
                                              font-family: Lato, Poppins,
                                                'Helvetica', non-serif;
                                              color: #021f2a;
                                            "
                                            >|</span
                                          ><!--[if mso]></td><!
                                          [endif]--><!--[if mso]><td style="padding-top:15px;padding-right:10px;padding-bottom:5px;padding-left:10px"><!
                                          [endif]--><a
                                            href="https://e-multicontent.com/about-us/"
                                            target="_blank"
                                            style="
                                              mso-hide: false;
                                              padding-top: 15px;
                                              padding-bottom: 5px;
                                              padding-left: 10px;
                                              padding-right: 10px;
                                              display: inline-block;
                                              color: #021f2a;
                                              font-family: Lato, Poppins,
                                                'Helvetica', non-serif;
                                              font-size: 14px;
                                              text-decoration: none;
                                              letter-spacing: 1px;
                                            "
                                            >About us</a
                                          ><!--[if mso]></td><!
                                          [endif]--><!--[if mso]></tr></table><![endif]-->
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                            <div
                              class="spacer_block block-5"
                              style="
                                height: 20px;
                                line-height: 20px;
                                font-size: 1px;
                              "
                            >
                              &#8202;
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="row row-3"
              align="center"
              width="100%"
              border="0"
              cellpadding="0"
              cellspacing="0"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt">
              <tr>
              <p
                style="
                  padding:20px;
                  mso-line-height-alt: 57px;
                  letter-spacing: normal;
                  font-size: 20px;
                  width:680px;
                  max-width:680px;
                  margin: 0 auto;
                  font-weight:800;
                  background-color: #fff;
                  text-align:center;
                        font-family: Poppins, Lato,
                        'Helvetica', non-serif;
                "
              >
                 Thank you for signing up for <strong><span style="color:#36b3d2;">newsletter</span>!</strong>

              <br>

                <span style="font-size: 14px"><em>Expect fresh content to arrive in your inbox, meanwhile You can...</em></span>

              </p>
                        </tr>
            </table>

            <table
              class="row row-2"
              align="center"
              width="100%"
              border="0"
              cellpadding="0"
              cellspacing="0"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      class="row-content stack"
                      align="center"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      role="presentation"
                      style="
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        background-color: #fff;
                        background-position: center top;
                        color: #000;
                        width: 680px;
                        margin: 0 auto;
                      "
                      width="680"
                    >
                      <tbody>
                        <tr>
                          <td
                            class="column column-1"
                            width="50%"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              font-weight: 400;
                              text-align: left;
                              vertical-align: top;
                              border-top: 0px;
                              border-right: 0px;
                              border-bottom: 0px;
                              border-left: 0px;
                            "
                          >
                            <table
                              class="image_block block-1"
                              width="100%"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              role="presentation"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                              "
                            >
                              <tr>
                                <td class="pad" style="width: 100%">
                                  <div
                                    class="alignment"
                                    align="center"
                                    style="line-height: 10px"
                                  ><img
                                      src="https://e-multicontent.com/img/share-brief.png"
                                      style="
                                        display: block;
                                        height: auto;
                                        max-width: 250px;
                                        width: 100%;
                                              font-family: Lato, Poppins,
                                                'Helvetica', non-serif;
                                      "
                                      width="340"
                                      alt="dziękujemy!"
                                      title="dziękujemy!"
                                    />
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td
                            class="column column-2"
                            width="50%"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              font-weight: 400;
                              text-align: left;
                              padding-bottom: 5px;
                              padding-top: 5px;
                              vertical-align: top;
                              border-top: 0px;
                              border-right: 0px;
                              border-bottom: 0px;
                              border-left: 0px;
                            "
                          >
                            <div
                              class="spacer_block block-1"
                              style="
                                height: 15px;
                                line-height: 15px;
                                font-size: 1px;
                              "
                            >
                              &#8202;
                            </div>
                            <table
                              class="text_block block-2"
                              width="100%"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              role="presentation"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                word-break: break-word;
                              "
                            >
                              <tr>
                                <td
                                  class="pad"
                                  style="
                                    padding-bottom: 10px;
                                    padding-left: 25px;
                                    padding-right: 25px;
                                    padding-top: 10px;
                                  "
                                >
                                  <div
                                    style="
                                      font-family: Lato, Poppins,
                                                'Helvetica', non-serif;
                                    "
                                  >

                                  </div>
                                </td>
                              </tr>
                            </table>
                            <div
                              class="spacer_block block-3"
                              style="
                                height: 15px;
                                line-height: 15px;
                                font-size: 1px;
                              "
                            >
                              &#8202;
                            </div>
                            <table
                              class="button_block block-4"
                              width="100%"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              role="presentation"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                              "
                            >
                              <tr>
                                <td class="pad" style="text-align: center">
                                  <div class="alignment" align="center">
                                    <!--[if mso]><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="https://www.example.com" style="height:46px;width:177px;v-text-anchor:middle;" arcsize="22%" strokeweight="1.5pt" strokecolor="#000000" fill="false"><w:anchorlock/><v:textbox inset="0px,0px,0px,0px"><center style="color:#021f2a; font-family:'Trebuchet MS', Tahoma, sans-serif; font-size:14px"><!
                                    [endif]--><a
                                                                          href="https://e-multicontent.com/blog/"
                                                                          target="_blank"
                                                                          style="
                                                                            text-decoration: none;
                                                                            display: inline-block;
                                                                            color: #021f2a;
                                                                            background-color: white;
                                                                            border-radius: 10px;
                                                                            width: auto;
                                                                            border-top: 2px solid #36b3d2;
                                                                            font-weight: undefined;
                                                                            border-right: 2px solid #36b3d2;
                                                                            border-bottom: 2px solid #36b3d2;
                                                                            border-left: 2px solid #36b3d2;
                                                                            padding-top: 5px;
                                                                            padding-bottom: 5px;
                                                                            font-family: Lato, Poppins,
                                                                                    'Helvetica', non-serif;
                                                                            font-size: 14px;
                                                                            text-align: center;
                                                                            mso-border-alt: none;
                                                                            word-break: keep-all;
                                                                    min-width:75%;
                                                                          "
                                                                          ><span
                                                                            style="
                                                                              padding-left: 20px;
                                                                              padding-right: 20px;
                                                                              font-size: 14px;
                                                                              display: inline-block;
                                                                              letter-spacing: 2px;
                                                                            "
                                                                            ><span
                                                                              style="
                                                                                font-size: 16px;
                                                                                word-break: break-word;
                                                                                line-height: 2;
                                                                                mso-line-height-alt: 32px;
                                                                              "
                                                                              ><strong
                                                                                ><span
                                                                                  style="font-size: 14px"
                                                                                  data-mce-style="font-size:14px;"
                                                                                  >READ OUR BLOG</span
                                                                                ></strong
                                                                              ></span
                                                                            ></span
                                                                          ></a
                                                                        >
                                                                        <br><br>
                                                                        <a
                                                                          href="https://e-multicontent.com/portfolio/"
                                                                          target="_blank"
                                                                          style="
                                                                            text-decoration: none;
                                                                            display: inline-block;
                                                                            color: #021f2a;
                                                                            background-color: white;
                                                                            border-radius: 10px;
                                                                            width: auto;
                                                                            border-top: 2px solid #36b3d2;
                                                                            font-weight: undefined;
                                                                            border-right: 2px solid #36b3d2;
                                                                            border-bottom: 2px solid #36b3d2;
                                                                            border-left: 2px solid #36b3d2;
                                                                            padding-top: 5px;
                                                                            padding-bottom: 5px;
                                                                            font-family: Lato, Poppins,
                                                                                    'Helvetica', non-serif;
                                                                            font-size: 14px;
                                                                            text-align: center;
                                                                            mso-border-alt: none;
                                                                            word-break: keep-all;
                                                                            min-width:75%;
                                                                          "
                                                                          ><span
                                                                            style="
                                                                              padding-left: 20px;
                                                                              padding-right: 20px;
                                                                              font-size: 14px;
                                                                              display: inline-block;
                                                                              letter-spacing: 2px;
                                                                            "
                                                                            ><span
                                                                              style="
                                                                                font-size: 16px;
                                                                                word-break: break-word;
                                                                                line-height: 2;
                                                                                mso-line-height-alt: 32px;
                                                                              "
                                                                              ><strong
                                                                                ><span
                                                                                  style="font-size: 14px"
                                                                                  data-mce-style="font-size:14px;"
                                                                                  >VISIT OUR PORTFOLIO</span
                                                                                ></strong
                                                                              ></span
                                                                            ></span
                                                                          ></a
                                                                        >

                                                                        <br><br>
                                                                        <a
                                                                          href="https://e-multicontent.com/offer/"
                                                                          target="_blank"
                                                                          style="
                                                                            text-decoration: none;
                                                                            display: inline-block;
                                                                            color: #021f2a;
                                                                            background-color: white;
                                                                            border-radius: 10px;
                                                                            width: auto;
                                                                            border-top: 2px solid #36b3d2;
                                                                            font-weight: undefined;
                                                                            border-right: 2px solid #36b3d2;
                                                                            border-bottom: 2px solid #36b3d2;
                                                                            border-left: 2px solid #36b3d2;
                                                                            padding-top: 5px;
                                                                            padding-bottom: 5px;
                                                                            font-family: Lato, Poppins,
                                                                                    'Helvetica', non-serif;
                                                                            font-size: 14px;
                                                                            text-align: center;
                                                                            mso-border-alt: none;
                                                                            word-break: keep-all;
                                                                            min-width:75%;
                                                                          "
                                                                          ><span
                                                                            style="
                                                                              padding-left: 20px;
                                                                              padding-right: 20px;
                                                                              font-size: 14px;
                                                                              display: inline-block;
                                                                              letter-spacing: 2px;
                                                                            "
                                                                            ><span
                                                                              style="
                                                                                font-size: 16px;
                                                                                word-break: break-word;
                                                                                line-height: 2;
                                                                                mso-line-height-alt: 32px;
                                                                              "
                                                                              ><strong
                                                                                ><span
                                                                                  style="font-size: 14px"
                                                                                  data-mce-style="font-size:14px;"
                                                                                  >SEE OUR OFFER</span
                                                                                ></strong
                                                                              ></span
                                                                            ></span
                                                                          ></a
                                                                        >    <!--[if mso]></center></v:textbox></v:roundrect><![endif]-->
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="row row-3"
              align="center"
              width="100%"
              border="0"
              cellpadding="0"
              cellspacing="0"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      class="row-content stack"
                      align="center"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      role="presentation"
                      style="
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        background-color: #fff;
                        background-position: center top;
                        color: #000;
                        width: 680px;
                        margin: 0 auto;
                      "
                      width="680"
                    >
                      <tbody>
                        <tr>
                          <td
                            class="column column-1"
                            width="100%"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              font-weight: 400;
                              text-align: left;
                              vertical-align: top;
                              border-top: 0px;
                              border-right: 0px;
                              border-bottom: 0px;
                              border-left: 0px;
                            "
                          >
                            <div
                              class="spacer_block block-1"
                              style="
                                height: 25px;
                                line-height: 25px;
                                font-size: 1px;
                              "
                            >
                              &#8202;
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>

            <table
              class="row row-5"
              align="center"
              width="100%"
              border="0"
              cellpadding="0"
              cellspacing="0"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      class="row-content stack"
                      align="center"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      role="presentation"
                      style="
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        background-color: #fff;
                        background-position: center top;
                        color: #000;
                        width: 680px;
                        margin: 0 auto;
                      "
                      width="680"
                    >
                      <tbody>
                        <tr>
                          <td
                            class="column column-1"
                            width="100%"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              font-weight: 400;
                              text-align: left;
                              padding-bottom: 15px;
                              padding-top: 15px;
                              vertical-align: top;
                              border-top: 0px;
                              border-right: 0px;
                              border-bottom: 0px;
                              border-left: 0px;
                              padding:0px;
                            "
                          >
                            <table
                              class="image_block block-2"
                              width="100%"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              role="presentation"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                background-image: linear-gradient(
                                  30deg,
                                  rgba(181, 239, 250, 1) 33%,
                                  rgba(54, 179, 210, 1) 100%
                                );
                                padding: 20px;
                              "
                            >
                              <tr>
                                <td class="pad" style="width: 100%">
                                  <div
                                    class="alignment"
                                    align="center"
                                    style="line-height: 10px"
                                  >
                                    <h1
                                      style="
                                        margin: 10px;
                                        color: white;
                                        direction: ltr;
                                        font-family: 'Montserrat',
                                          'Trebuchet MS', 'Lucida Grande',
                                          'Lucida Sans Unicode', 'Lucida Sans',
                                          Tahoma, sans-serif;
                                        font-size: 23px;
                                        font-weight: bold;
                                        letter-spacing: normal;
                                        line-height: 120%;
                                        text-align: center;
                                        margin-top: 0;
                                        margin-bottom: 0;
                                      "
                                    >
                                      <strong>An offer tailored to your needs.</strong>
                                    </h1>
                                    <p
                                      style="
                                        margin: 10px;
                                        font-family: 'Montserrat',
                                          'Trebuchet MS', 'Lucida Grande',
                                          'Lucida Sans Unicode', 'Lucida Sans',
                                          Tahoma, sans-serif;
                                        text-align: center;
                                        line-height: 1.5;
                                        mso-line-height-alt: 24px;
                                      "
                                    >
                                      <span style="font-size: 16px"
                                        >Fill out a non-binding, short brief with questions that will help us determine how to accelerate your business.</span
                                      >
                                    </p>
                                    <div class="alignment" align="center">
                                      <!--[if mso]><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="https://www.example.com" style="height:46px;width:147px;v-text-anchor:middle;" arcsize="22%" strokeweight="1.5pt" strokecolor="#000000" fill="false"><w:anchorlock/><v:textbox inset="0px,0px,0px,0px"><center style="color:#021f2a; font-family:'Trebuchet MS', Tahoma, sans-serif; font-size:14px"><!
                                      [endif]--><a
                                        href="https://docs.google.com/forms/d/e/1FAIpQLSfZHfwPESZ4qMZljC9_L-WKIuQuAgLA-dadqM1CvjWkKix1cw/viewform"
                                        target="_blank"
                                        style="
                                          text-decoration: none;
                                          display: inline-block;
                                          color: #021f2a;
                                          background-color: transparent;
                                          border-radius: 10px;
                                          width: auto;
                                          border-top: 2px solid #000000;
                                          font-weight: undefined;
                                          border-right: 2px solid #000000;
                                          border-bottom: 2px solid #000000;
                                          border-left: 2px solid #000000;
                                          padding-top: 5px;
                                          padding-bottom: 5px;
                                          font-family: 'Montserrat',
                                            'Trebuchet MS', 'Lucida Grande',
                                            'Lucida Sans Unicode', 'Lucida Sans',
                                            Tahoma, sans-serif;
                                          font-size: 14px;
                                          text-align: center;
                                          mso-border-alt: none;
                                          word-break: keep-all;
                                          margin-bottom: 15px;
                                        "
                                        ><span
                                          style="
                                            padding-left: 20px;
                                            padding-right: 20px;
                                            font-size: 14px;
                                            display: inline-block;
                                            letter-spacing: 2px;
                                          "
                                          ><span style="word-break: break-word"
                                            ><span
                                              style="line-height: 28px"
                                              data-mce-style
                                              >FILL OUT THE BRIEF</span
                                            ></span
                                          ></span
                                        ></a><!--[if mso]></center></v:textbox></v:roundrect><![endif]-->
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>


            <table
              class="row row-2"
              align="center"
              width="100%"
              border="0"
              cellpadding="0"
              cellspacing="0"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      class="row-content stack"
                      align="center"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      role="presentation"
                      style="
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        background-color: #fff;
                        background-position: center top;
                        color: #000;
                        width: 680px;
                        margin: 0 auto;
                        background-color:#121212;
                      "
                      width="680"
                    >
                      <tbody>
                        <tr>
                          <td
                            class="column column-1"
                            width="50%"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              font-weight: 400;
                              text-align: left;
                              vertical-align: top;
                              border-top: 0px;
                              border-right: 0px;
                              border-bottom: 0px;
                              border-left: 0px;
                            "
                          >
                            <table
                              class="text_block block-2"
                              width="100%"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              role="presentation"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                word-break: break-word;
                              "
                            >
                              <tr>
                                <td
                                  class="pad"
                                  style="
                                    padding-bottom: 10px;
                                    padding-left: 25px;
                                    padding-right: 25px;
                                    padding-top: 10px;
                                  "
                                >
                                  <div
                                    style="
                                      font-family: Lato, Poppins,
                                                'Helvetica', non-serif;
                                    "
                                  >
                                    <div
                                      class
                                      style="
                                        font-size: 12px;
                                        font-family: Poppins,
                                                'Helvetica', non-serif;
                                        mso-line-height-alt: 18px;
                                        color: #021f2a;
                                        line-height: 1.5;
                                      "
                                    >
                                      <h3
                                    style="
                                      margin: 0;
                                      color: #ffffff;
                                      direction: ltr;
                                      font-family: Montserrat, Trebuchet MS,
                                        Lucida Grande, Lucida Sans Unicode,
                                        Lucida Sans, Tahoma, sans-serif;
                                      font-size: 20px;
                                      font-weight: 300;
                                      letter-spacing: 6px;
                                      line-height: 120%;
                                      text-align: center;
                                      margin-top: 15px;
                                      margin-bottom: 0;
                                    "
                                  >
                                    <strong>#Do you know that?</strong>
                                  </h3>
                                  <p
                                    style="
                                      color: #36b3d2;
                                      direction: ltr;
                                      font-family: Montserrat, Trebuchet MS,
                                        Lucida Grande, Lucida Sans Unicode,
                                        Lucida Sans, Tahoma, sans-serif;
                                      font-size: 16px;
                                      margin: 20px;
                                      font-weight: 300;
                                    "
                                  >
                                    <em>
                                      The Greek language belongs to the Indo-European language family, in which it occupies a separate group - Hellenic, because it is not similar to any other language.</em
                                    >
                                  </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td
                            class="column column-2"
                            width="50%"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              font-weight: 400;
                              text-align: left;
                              vertical-align: top;
                              border-top: 0px;
                              border-right: 0px;
                              border-bottom: 0px;
                              border-left: 0px;
                            "
                          >

                            <table
                              class="image_block block-1"
                              width="100%"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              role="presentation"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                              "
                            >
                              <tr>
                                <td class="pad" style="width: 100%">
                                  <div
                                    class="alignment"
                                    align="center"

                                  >
                                    <img
                                      src="https://e-multicontent.com/img/blog-anime.gif"
                                      style="
                                        display: block;
                                        height: auto;
                                        border: 0;
                                        margin-top:15px;
                                        max-width: 160px;
                                        width: 100%;
                                      "
                                      width="340"
                                      alt="animacja e-multicontent"
                                      title="animacja e-multicontent"
                                    />
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
              class="row row-10"
              align="center"
              width="100%"
              border="0"
              cellpadding="0"
              cellspacing="0"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      class="row-content stack"
                      align="center"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      role="presentation"
                      style="
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        color: #000;
                        width: 680px;
                        margin: 0 auto;
                      "
                      width="680"
                    >
                      <tbody>
                        <tr>
                          <td
                            class="column column-1"
                            width="100%"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              font-weight: 400;
                              text-align: left;
                              vertical-align: top;
                              border-top: 0px;
                              border-right: 0px;
                              border-bottom: 0px;
                              border-left: 0px;
                            "
                          >
                            <table
                              class="image_block block-1"
                              width="100%"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              role="presentation"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                background-image: linear-gradient(
                                  30deg,
                                  rgba(181, 239, 250, 1) 33%,
                                  rgba(54, 179, 210, 1) 100%
                                );
                                padding: 20px;
                              "
                            >
                              <tr>
                                <td
                                  class="pad"
                                  style="padding-top: 15px; width: 100%"
                                >
                                  <div
                                    class="alignment"
                                    align="center"
                                    style="line-height: 10px"
                                  >
                                    <img                                   src="https://e-multicontent.com/img/logo-e-white.png"
                                      style="
                                        display: block;
                                        height: auto;
                                        border: 0;
                                        max-width: 150px;
                                        width: 100%;
                                      "
                                      width="150"
                                      alt="logo"
                                      title="logo"
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  class="pad"
                                  style="
                                    padding-bottom: 10px;
                                    padding-left: 10px;
                                    padding-right: 10px;
                                    padding-top: 20px;
                                    text-align: center;
                                  "
                                >
                                  <div class="alignment" align="center">
                                    <table
                                      class="social-table"
                                      width="138px"
                                      border="0"
                                      cellpadding="0"
                                      cellspacing="0"
                                      role="presentation"
                                      style="
                                        mso-table-lspace: 0pt;
                                        mso-table-rspace: 0pt;
                                        display: inline-block;
                                      "
                                    >
                                      <tr>
                                        <td style="padding: 0 7px 0 7px">
                                          <a                                     href="https://www.facebook.com/emulticontent.ecommerce"
                                            target="_blank"
                                            ><img
                                              src="https://e-multicontent.com/img/fb.png"
                                              width="32"
                                              height="32"
                                              alt="Facebook"
                                              title="facebook"
                                              style="
                                                display: block;
                                                height: auto;
                                                border: 0;
                                              "
                                          /></a>
                                        </td>
                                        <td style="padding: 0 7px 0 7px">
                                          <a
                                            href="https://www.linkedin.com/showcase/multicontent"
                                            target="_blank"
                                            ><img
                                              src="https://e-multicontent.com/img/in.png"
                                              width="32"
                                              height="32"
                                              alt="Linkedin"
                                              title="linkedin"
                                              style="
                                                display: block;
                                                height: auto;
                                                border: 0;
                                              "
                                          /></a>
                                        </td>
                                        <td style="padding: 0 7px 0 7px">
                                          <a
                                            href="https://www.google.com/search?q=e-multicontent"
                                            target="_blank"
                                            ><img
                                              src="https://e-multicontent.com/img/go.png"
                                              width="32"
                                              height="32"
                                              alt="google"
                                              title="google"
                                              style="
                                                display: block;
                                                height: auto;
                                                border: 0;
                                              "
                                          /></a>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            </table>
                            <table
                              class="text_block block-4"
                              width="100%"
                              border="0"
                              cellpadding="30"
                              cellspacing="0"
                              role="presentation"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                word-break: break-word;
                              "
                            >
                              <tr>
                                <td class="pad">
                                  <div style="font-family: sans-serif">
                                    <div
                                      class
                                      style="
                                        font-size: 12px;
                                        font-family: Montserrat, Trebuchet MS,
                                          Lucida Grande, Lucida Sans Unicode,
                                          Lucida Sans, Tahoma, sans-serif;
                                        mso-line-height-alt: 18px;
                                        color: #393d47;
                                        line-height: 1.5;
                                      "
                                    >
                                      <p
                                        style="
                                          margin: 0;
                                          font-size: 14px;
                                          text-align: center;
                                          mso-line-height-alt: 18px;
                                        "
                                      >
                                        <span style="font-size: 12px"
                                          ><span style="color: #999999"
                                            >Do you want to unsubscribe from the newsletter?</span
                                          ></span
                                        >
                                      </p>
                                      <p
                                        style="
                                          margin: 0;
                                          font-size: 14px;
                                          text-align: center;
                                          mso-line-height-alt: 18px;
                                        "
                                      >
                                        <span style="font-size: 12px">
                                           <span style="color: #999999"
                                             >Contact us via
                                             <a
                                               href="https://e-multicontent.com/contact-us/"
                                               target="_blank"
                                               styles="
                                                 text-decoration: underline;
                                                 color: dodgerblue;
                                               "
                                               rel="noopener"
                                               >contact form</a
                                             >
                                             or write to us
                                             <a
                                               href="mailto:info@e-multicontent.com"
                                               target="_blank"
                                               styles="
                                                 text-decoration: underline;
                                                 color: dodgerblue;
                                               "
                                               rel="noopener"
                                               >email</a
                                             >.</span>
                                          </span></p>
                                      <p
                                        style="
                                          margin: 0;
                                          font-size: 14px;
                                          text-align: center;
                                          mso-line-height-alt: 18px;
                                        "
                                      >
                                        <span
                                          style="
                                            font-size: 12px;
                                            color: #999999;
                                          "
                                          >© 2023 e-multicontent.com - All rights reserved.</span
                                        >
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
                  </td>
                </tr>
              </tbody>
            </table>
            </table>

          </td>
        </tr>
      </tbody>
    </table>
    <!-- End -->
  </body>
</html>
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
    .then(() => {
      const messageData = {
        from: 'e-multicontent team <info@e-multicontent.com>',
        to: document.getElementById('email').value,
        subject: 'Thank you for signing up!',
        html: htmldata
      };
      //console.log(client)

      client.messages.create(DOMAIN, messageData)
       .then((res) => {
         console.log(res);
       })
       .catch((err) => {
         console.error(err);
       });
       navigate(form.getAttribute('action'))
    })
      .catch(error => alert(error))
  }

  render() {
    return (
      <div>
      {/* file input fields */}
      <section className="section" style={{minWidth:'200px',maxWidth:'600px'}}>




    {/*  backgroundImage: `url(${
      '/img/tlo2.svg'
    })`,*/}






        <div  style={{borderRadius:'10px',padding:'5px',width:'100%'}}>
        {/*message*/}
        <div className="box container"  style={{marginTop:'-50px',backgroundColor:'#36b3d2',color:'#222222',padding:'35px',borderRadius:'25px',minWidth:'350px',maxWidth:'650px',marginLeft:'10%'}}>
          <div className="content " style={{
          backgroundColor:'rgba(255,255,255,0.0)',
          width:'90%',

          }}>

            <h1 style={{

            zIndex:'1000',
            color:'white'
          }}>E-Book &nbsp;&nbsp;</h1>
              <p style={{

              zIndex:'1000',

            }}> Subscribe to receive our <br></br> <b>free e-book:</b><br></br>
            <br></br>
             <q><b>How to increase your e-shop sales</b> in five easy steps.</q></p>

        </div>


        <form
          name="ebook"
          method="post"
          action="/contact-us/thank-you/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
          style={{marginRight:'10%', maxWidth:'500px'}}
        >


          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="ebook" />
          <div hidden>
            <label>
              Don’t fill this out:{' '}

              <input name="bot-field"
              onChange={this.handleChange}
              />
            </label>
          </div>
          <div className="field">
            <label className="label" align="left" style={{color:'#222222',fontSize:'13px'}} htmlFor={'email'}>
              Email<sup>*</sup>:
            </label>
            <div className="control">
              <input
                className="input"
                type={'email'}
                name={'email'}
                onChange={this.handleChange}
                id={'email'}
                required={true}
                style={{maxWidth:'350px'}}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" align="left" style={{color:'#222222',fontSize:'13px'}} htmlFor={'name'}>
              Name<sup>*</sup>:
            </label>
            <div className="control">
              <input
                className="input"
                type={'text'}
                name={'name'}
                onChange={this.handleChange}
                id={'name'}
                required={true}
                style={{maxWidth:'350px'}}
              />
            </div>
          </div>



          <div className="control">
          <input required={true} type="checkbox" id="privacy" defaultChecked="true" name="privacy" value="none"/>&nbsp;&nbsp;
          <label style={{fontSize: '10px',color:'#222222'}}  htmlFor="privacy">I agree to receive the e-book, newsletter and commercial information from <br />
          <b> e-multicontent.com</b> according to our <Link style={{color:'white',padding:'1px'}} to="/privacy-policy/">Privacy policy</Link>.</label><br></br><br></br>

          </div>

          <div className="field">
            <button className="btnblog" type="submit">
            Subscribe
            </button>

          </div>
        </form>
<br></br>
        </div>





        </div>


































      </section>
      </div>
    )
  }
}
